'use client';

import 'mapbox-gl/dist/mapbox-gl.css';
import React, {useEffect, useRef} from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = 'pk.eyJ1Ijoic2lzZWtpcHAiLCJhIjoiY2tzNjZpaXF0MDd6bTJxcXlkcndnanNrcCJ9.LBs7-8uFmm8GVB-v18vTkw';

const Map = (): React.JSX.Element => {
    const mapContainer = useRef(null);

    useEffect(() => {
        if (mapContainer.current) {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/light-v11',
                center: [7.6426, 47.7371],
                zoom: 16.69
            });

            // Create default markers
            new mapboxgl.Marker().setLngLat([7.6425, 47.7372]).addTo(map)

            // Add navigation control (the +/- zoom buttons)
            map.addControl(new mapboxgl.NavigationControl(), "top-right");

            // Clean up on unmount
            return () => map.remove();
        }

    }, []);

    return (
        <div id="map" ref={mapContainer} className="h-80 rounded-2xl"></div>
    );
};

export default Map;