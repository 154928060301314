'use client';

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import salon1 from '../public/images/salon/Salon1.jpeg';
import salon2 from '../public/images/salon/Salon2.jpeg';
import salon3 from '../public/images/salon/Salon3.jpeg';
import salon4 from '../public/images/salon/Salon4.jpeg';
import salon5 from '../public/images/salon/Salon5.jpeg';
import salon6 from '../public/images/salon/Salon6.jpeg';
import salon7 from '../public/images/salon/Salon7.jpeg';
import salon9 from '../public/images/salon/Salon9.jpeg';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import Image from "next/image";

const ImageSlider = (): React.JSX.Element => {
    return (
        <Swiper className={"rounded-md"}
                modules={[Navigation, Pagination, Autoplay]}
                slidesPerView={1}
                centeredSlides={true} centeredSlidesBounds={true}
                navigation pagination={{clickable: true}}
                autoplay={{disableOnInteraction: false, delay: 5000}}>
            <SwiperSlide><Image src={salon1}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
            <SwiperSlide><Image src={salon2}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
            <SwiperSlide><Image src={salon3}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
            <SwiperSlide><Image src={salon4}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
            <SwiperSlide><Image src={salon5}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
            <SwiperSlide><Image src={salon6}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
            <SwiperSlide><Image src={salon7}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
            <SwiperSlide><Image src={salon9}
                                alt={"Dein Wohlfühlfriseur by Katrin Kipping - Salon Bild"}/></SwiperSlide>
        </Swiper>
    );

};

export default ImageSlider;